import React, { useEffect, useState } from 'react';
import { Content, Items, ItemButton } from './styles';
import Settings from '~/assets/svg/Settings';
import { useStores, REACT_APP_PRIME_SSO_CONFIG_URL } from '~/utils';
import { observer } from 'mobx-react';
import { Path } from '~/routes/routes.path';
import { Tooltip } from '@material-ui/core';
import StarRounded from '@material-ui/icons/StarRounded';  
import Book from '~/assets/svg/Book';  
import Flask from '~/assets/svg/Flask';

const Menu: React.FC = () => {
  const { routing } = useStores();
  const [selectedPath, setSelectedPath] = useState<string | null>(null);

  useEffect(() => {
    setSelectedPath(routing.location.pathname);
  }, [routing.location.pathname]);

  const navigateTo = (path: string) => {
    routing.replace(path);
  };

  const SSO_CONFIG_URL = () => {
    if (REACT_APP_PRIME_SSO_CONFIG_URL) {
      window.location.href = REACT_APP_PRIME_SSO_CONFIG_URL;
    } else {
      console.error('SSO Config URL is not defined');
    }
  };

  return (
    <Content>
      <Items>
      </Items>
      <Items>
        <Tooltip title="Epic Gen" placement="right">
          <ItemButton selected={selectedPath === Path.EPIC} onClick={() => navigateTo(Path.EPIC)}>
            <StarRounded style={{ fontSize: '32px', color: '#e3e3e3' }} />
          </ItemButton>
        </Tooltip>

        <Tooltip title="Story Gen" placement="right">
          <ItemButton
            selected={selectedPath === Path.STORY} onClick={() => navigateTo(Path.STORY)}>
              <Book />
          </ItemButton>
        </Tooltip>

        <Tooltip title="Test Case Gen" placement="right">
          <ItemButton
            selected={selectedPath === Path.TESTS} onClick={() => navigateTo(Path.TESTS)}>
              <Flask /> 
          </ItemButton>
        </Tooltip>
      </Items>

      <Items>
        <Tooltip title="Configuração" placement="right">
          <ItemButton
            border onClick={() => SSO_CONFIG_URL()}>
            <Settings size={32}/>
          </ItemButton>
        </Tooltip>
      </Items>
    </Content>
  );
};

export default observer(Menu);