import React, { FC, useEffect, useState } from 'react';
import {
  IconButton,
  MenuItem,
  Tooltip,
  Switch,
  Box,
  Menu,
  Button,
} from '@material-ui/core';
import { Check, Close, GetApp } from '@material-ui/icons';
import { Jira, More, Play } from '~/assets/svg';
import {
  DropDown,
  Header,
  If,
  Menu as Sidebar,
  Modal,
  CardAccordion,
} from '~/components';
import {
  AlignFields,
  Background,
  Board,
  ButtonBox,
  ButtonsContainer,
  CardItemsSpace,
  CustomTextField,
  GeneratedItemsSpace,
  MoreButtonOnCard,
  MoreButtonOnProject,
  Panel,
  SectionFields,
  StyledButton,
  StyledReactLoading,
  TextEmpty,
  Title,
  ToggleContent,
  ToggleText,
  VerticalDivisor,
  WrapperFields,
} from '~/styles';
import { colors } from '~/theme';
import { observer, useFormikContext, useStores, alert, REACT_APP_HEALTH_BEAT_API_URL, REACT_APP_ENV } from '~/utils';
import { Request } from '~/services';
import { getTenantIdCookie } from '~/utils/tenants';

type Props = {};

const Epic: FC<Props> = (): JSX.Element => {
  const { values, handleChange, isSubmitting, submitForm, setFieldValue } =
    useFormikContext<EpicType.Forms>();
  const { epic, project } = useStores();
  const [autoAmount, setAutoAmount] = useState(false);
  const [selectedEpicId, setSelectedEpicId] = useState<null | number>(null);
  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(null);
  const [anchorElCard, setAnchorElCard] = useState<null | HTMLElement>(null);
  const [anchorElProject, setAnchorElProject] = useState<null | HTMLElement>(null); 
  const [editCard, setEditCard] = useState(false);
  const [editedEpic, setEditedEpic] = useState<any>(null); 
  const [isEditing, setIsEditing] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState(project.selected?.name || '');

  useEffect(() => {
    project.getJiraProjects();
    project.orderProjects();
    if(REACT_APP_ENV === 'DEV') {
      const healthCheck = async () => {
        try {
          const tenantId = getTenantIdCookie();
          const response = await Request(REACT_APP_HEALTH_BEAT_API_URL || "")
          .get(`/heart-beat/${tenantId}`, {
            headers: {
            'app': 'EpicGen',
            },
          });
        } catch (error) {
          console.log('Erro ao tentar fazer health check:', error);
        }
      };

      const interval = setInterval(() => {
        if (document.visibilityState === 'visible') {
          healthCheck();
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  const hasGeneratedItemsForSelectedProject = () => {
    return (
      epic.generatedItems.length > 0 &&
      epic.generatedItems.some(
        (item) => item.project.id === project.selected?.id,
      )
    );
  };

  const hasLikedEpicsForSelectedProject = () => {
    return epic.items.some(
      (item) => item.feedback === 2 && item.project.id === project.selected?.id,
    );
  };

  const defineAmount = () => {
    if (!autoAmount) {
      values.amount = 1;
      alert({
        message: 'A quantidade de épicos gerados será definida manualmente',
        type: 'info',
        position: 'top-center',
      });
    } else {
      values.amount = 0;
      alert({
        message: 'A quantidade de épicos gerados será definida automaticamente',
        type: 'info',
        position: 'top-center',
      });
    }
    setAutoAmount(!autoAmount);
  };

  const handleSaveClick = () => {
    if (project.selected) {
      if(!editedProjectName.trim()) {
        alert({ message: 'Nome do projeto não pode ser vazio', type: 'warning', position: 'top-center' });
      }else{
        project.selected.name = editedProjectName;
      }
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedProjectName(project.selected?.name || '');
    setIsEditing(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: { key: string; }) => {
      if (isEditing) {
        if (event.key === 'Enter') {
          handleSaveClick();
        } else if (event.key === 'Escape') {
          handleCancelClick();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing, handleSaveClick, handleCancelClick]);

  const handleUpdateEpic = async () => {
    if (!editedEpic?.content?.summary || !editedEpic?.content?.description) {
      alert({
        message: 'O título e a descrição do épico são obrigatórios.',
        type: 'error',
        position: 'top-center',
      });
      return;
    }
  
    const payload = {
      id: editedEpic.id,
      artifact_id: editedEpic.artifact_id,
      item_id: editedEpic.item_id,
      content: {
        summary: editedEpic.content.summary,
        description: editedEpic.content.description,
      },
      feedback: editedEpic.feedback, // Inclua o feedback, se necessário
      project: editedEpic.project, // Certifique-se de que o projeto está correto
    };
  
    epic.edit(payload); 
  
    try {
      await epic.updateEpic(payload); // Atualiza o épico com o payload completo
      setEditCard(false); // Fecha o modal de edição
    } catch (error) {
      console.error('Erro ao tentar salvar o épico:', error);
    }
  };

  const handleEditEpic = (item: Epic.Type) => {
      setEditedEpic(item); 
      setEditCard(true);
  };

  return (
    <>
      <Header title="EpicGen" />
      <Sidebar />
      <Background>
        <Panel>
          <DropDown
            type="project"
            onClick={() => {
              setFieldValue('application', '');
              setFieldValue('objective', '');
              setFieldValue('amount', 0);
            }}
          />
          {/* Modal para edição de épico */}
          <Modal
            title="Editar Épico"
            open={editCard}
            onClose={() => {
              setEditCard(false);
              setAnchorElCard(null);
            }}
            width={600}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '8px 0',
              }}
            >
              <CustomTextField
                label="Título do Épico"
                placeholder="Digite o título do épico"
                variant="outlined"
                size="small"
                value={editedEpic?.content?.summary || ''} 
                onChange={(event) =>
                  setEditedEpic({
                  ...editedEpic,
                  content: { ...editedEpic.content, summary: event.target.value }, 
                  })
                }
                style={{ marginBottom: '20px' }}
                inputProps={{ maxLength: 255 }}
              />
              
              <CustomTextField
                label="Descrição"
                placeholder="Digite a descrição do épico"
                variant="outlined"
                size="small"
                multiline
                value={editedEpic?.content?.description || ''} 
                onChange={(event) =>
                  setEditedEpic({
                    ...editedEpic,
                    content: { ...editedEpic.content, description: event.target.value }, 
                  })
                }
                style={{ marginBottom: '20px' }}
                inputProps={{ maxLength: 1000 }}
              />
                <div style={{ marginTop: '8px', textAlign: 'right', fontSize: '12px', color: 'gray' }}>
                    {editedEpic?.content?.description?.length || 0}/1000 caracteres
                </div>
              <Button
                sx={{
                  backgroundColor: '#E25614',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#FD784E',
                  },
                }}
                variant="contained"
                size="small"
                onClick={() => {
                  handleUpdateEpic();
                  setEditCard(false);
                  setAnchorElCard(null);
                }} 
                style={{ marginTop: '16px' }}
              >
                Confirmar
              </Button>
            </div>
          </Modal>

          <div
            style={{
              width: '85%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >

            <StyledButton
              startIcon={<GetApp />}
              variant="contained"
              size="small"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorElExport(event.currentTarget)}
              disabled={!hasLikedEpicsForSelectedProject()}
              style={{ marginBottom: '16px' }}
            >
              Exportar
            </StyledButton>

            <Menu
              open={Boolean(anchorElExport)}
              onClose={() => setAnchorElExport(null)}
              anchorEl={anchorElExport}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              style={{ marginTop: '4px' }}
              PaperProps={{
                style: {
                  width: anchorElExport ? anchorElExport.clientWidth : 'auto',
                  minWidth: '100px',
                },
              }}
            >
              <MenuItem
                style={{ justifyContent: 'center' }}
                onClick={() => epic.exportItemsToTxt()}
              >
                TXT
              </MenuItem>
              <MenuItem
                style={{ justifyContent: 'center' }}
                onClick={() => {
                  epic.exportToPDF();
                  setAnchorElExport(null);
                }}
              >
                PDF
              </MenuItem>
              {project.selected?.type === 'jira' && (
                <MenuItem
                  style={{ justifyContent: 'center' }}
                  onClick={() => epic.exportToJira()}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Jira color="#000000DE" size={16} style={{ marginRight: '8px' }} />
                    Jira
                  </span>
                </MenuItem>
                )}
            </Menu>
            <CardItemsSpace items={hasLikedEpicsForSelectedProject()}>
              {!hasLikedEpicsForSelectedProject() ? (
                <TextEmpty style={{ paddingBottom: '16px' }}>Os épicos escolhidos aparecerão aqui</TextEmpty>
              ) : (
                <>
                  {epic.items
                    .filter((item: any) =>
                      project.selected
                        ? item.project.id === project.selected.id
                        : '',
                    )
                    .filter((item) => item.feedback === 2)
                    .map((item: any) => (
                      <Box
                        key={item.id}
                        sx={{
                          width: '100%',
                          borderRadius: 1,
                          marginBottom: '16px',
                          bgcolor: '#43688B',
                          textAlign: 'center',
                          padding: '24px 16px 16px 16px',
                          cursor: 'pointer',
                          color: 'white',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '16px',
                            fontWeight: '600',
                          }}
                        >
                          {item.content.summary}
                        </p>
                        <small
                          style={{
                            display: '-webkit-box',
                            width: '100%',
                            fontSize: '12px',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginTop: '8px',
                          }}
                        >
                          {item.content.description}
                        </small>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            textAlign: 'right',
                            position: 'relative',
                          }}
                        >
                          <MoreButtonOnCard
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                              setAnchorElCard(event.currentTarget);
                              setSelectedEpicId(item.id);
                            }}
                          >
                            <More />
                          </MoreButtonOnCard>
                          <Menu
                            open={Boolean(anchorElCard) && selectedEpicId === item.id}
                            onClose={() => {
                              setAnchorElCard(null);
                              setSelectedEpicId(null);
                            }}
                            anchorEl={anchorElCard}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            style={{ marginTop: '4px' }}
                            PaperProps={{
                              style: {
                                width: anchorElCard ? anchorElCard.clientWidth : 'auto',
                                minWidth: '100px',
                              },
                            }}
                          >
                            <MenuItem
                              style={{ justifyContent: 'center' }}
                              onClick={() => handleEditEpic(item)} 
                            >
                              Editar
                            </MenuItem>
                            <MenuItem
                              style={{ justifyContent: 'center' }}
                              onClick={() => {
                                epic.setFeedback(item.id, 3);
                                setAnchorElCard(null)
                              }}
                            >
                              Excluir
                            </MenuItem>
                          </Menu>
                        </div>
                      </Box>
                    ))}
                </>
              )}
            </CardItemsSpace>
          </div>
        </Panel>
        <VerticalDivisor />
        <Board>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Title>
                {isEditing ? (
                  <CustomTextField
                    value={editedProjectName} focused
                    label="Nome do Projeto"
                    placeholder="Digite o nome do projeto"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setEditedProjectName(e.target.value)}
                    inputProps={{ maxLength: 255 }}
                  />
                ) : (
                  <span style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                    {project.selected?.name}
                  </span>
                )}
                {!isEditing && (
                  <span onClick={() => setIsEditing(true)} style={{ marginLeft: 8 }}>
                  </span>
                )}
                {isEditing && (
                  <>
                    <IconButton onClick={handleSaveClick}>
                      <Check style={{ color: 'white' }}/>
                    </IconButton>
                    <IconButton onClick={handleCancelClick}>
                      <Close style={{ color: 'white' }}/>
                    </IconButton>
                  </>)}
              </Title>
              <div
                style={{ display: 'inline-block', position: 'relative' }}
              >
                {project.selected && project.selected.type !== 'jira' && (
                  <>
                    <MoreButtonOnProject onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorElProject(event.currentTarget)}>
                      <More />
                    </MoreButtonOnProject>
                    <Menu
                      open={Boolean(anchorElProject)}
                      onClose={() => setAnchorElProject(null)}
                      anchorEl={anchorElProject}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      style={{ marginTop: '4px' }}
                      PaperProps={{
                        style: {
                          width: anchorElProject ? anchorElProject.clientWidth : 'auto',
                          minWidth: '100px',
                        },
                      }}
                    >
                    <MenuItem
                      style={{ justifyContent: 'center' }}
                      onClick={() => {setIsEditing(true); 
                        setAnchorElProject(null);
                        setEditedProjectName(project.selected?.name || '') }}
                    >
                      Editar
                    </MenuItem>
                    <MenuItem
                      style={{ justifyContent: 'center' }}
                      onClick={() => {
                        project.remove(project.selected!.id);
                        setAnchorElProject(null);
                      }}
                    >
                      Excluir
                    </MenuItem>
                  </Menu>
                </>
                )}
              </div>
            </div>

            {project.selected && (
              <SectionFields>
                <AlignFields>
                  <WrapperFields>
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Sistema / Aplicação *"
                      variant="outlined"
                      sx={{ m: 1 }}
                      name="application"
                      disabled={isSubmitting}
                      onChange={handleChange('application')}
                      value={values.application}
                    />
                    <CustomTextField
                      fullWidth
                      multiline
                      maxRows={4}
                      size="small"
                      id="outlined-basic"
                      label="Descrição *"
                      variant="outlined"
                      sx={{ m: 1 }}
                      name="objective"
                      disabled={isSubmitting}
                      onChange={handleChange('objective')}
                      value={values.objective}
                    />
                  </WrapperFields>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <ToggleContent onClick={defineAmount}>
                      <Switch
                        checked={autoAmount}
                        disabled={isSubmitting}
                        sx={{
                          '&.MuiSwitch-root .MuiSwitch-switchBase': {
                            color: '#183E62',
                          },
                          '&.MuiSwitch-root .MuiSwitch-switchBase + .MuiSwitch-track':
                            {
                              backgroundColor: '#183E62',
                            },
                          '&.MuiSwitch-root .Mui-checked': {
                            color: '#FD784E',
                          },
                          '&.MuiSwitch-root .Mui-checked + .MuiSwitch-track': {
                            backgroundColor: '#FD784E',
                          },
                        }}
                        name="autoAmount"
                      />
                      <ToggleText>Definir manualmente a quantidade de épicos</ToggleText>
                    </ToggleContent>
                    <If condition={autoAmount}>
                      <CustomTextField
                        size="small"
                        style={{ width: '15%' }}
                        id="outlined-basic"
                        label="Quantidade de épicos"
                        variant="outlined"
                        type="number"
                        sx={{ m: 1 }}
                        name="amount"
                        disabled={isSubmitting}
                        value={values.amount}
                        InputProps={{
                          inputProps: { min: 1, max: 50, step: 1 },
                        }}
                        onChange={({ target }) => {
                          if (+target.value > 50) return;
                          setFieldValue('amount', target.value);
                        }}
                      />
                    </If>
                  </div>
                </AlignFields>
                <ButtonsContainer>
                  <Tooltip title="Gerar Épico" placement="left">
                    <ButtonBox>
                      <If condition={!isSubmitting}>
                        <Play
                          onPress={submitForm}
                          color={colors.primary.main}
                        />
                      </If>
                      <If condition={isSubmitting}>
                        <StyledReactLoading
                          type="bubbles"
                          color={colors.primary.main}
                        />
                      </If>
                    </ButtonBox>
                  </Tooltip>
                </ButtonsContainer>
              </SectionFields>
            )}
          </div>
          {!project.selected ? (
            <TextEmpty style={{ margin: '8px 0' }}>
              Nenhum projeto selecionado até o momento.
            </TextEmpty>
          ) : (
            <GeneratedItemsSpace items={hasGeneratedItemsForSelectedProject()}>
              {!hasGeneratedItemsForSelectedProject() ? (
                <TextEmpty>Nenhum épico gerado até o momento.</TextEmpty>
              ) : (
                <>
                  {epic.generatedItems
                    .filter((item) => item.project.id === project.selected?.id)
                    .map((item, index: number) => (
                      <CardAccordion
                        key={index}
                        title={item.content.summary}
                        content={item.content.description ? item.content.description : ''}
                        feedback={item.feedback}
                        onFeedbackChange={(newFeedback) => {
                          epic.setFeedback(item.id, newFeedback);
                          const epicItem = epic.items.find((epicItem) => epicItem.id === item.id);
                        }}
                      />
                    ))}
                </>
              )}
            </GeneratedItemsSpace>
          )}
        </Board>
      </Background>
    </>
  );
};

export default observer(Epic);
