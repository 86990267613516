import React, { FC, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Heading, ThumbContainer } from './styles';
import { ThumbUp, ThumbDown } from '@material-ui/icons';

type CardAccordionProps = {
  key: number;
  title: string;
  content: string;
  feedback: number; 
  onFeedbackChange: (newFeedback: number) => void; 
};

const CardAccordion: FC<CardAccordionProps> = ({
  title,
  content,
  feedback,
  onFeedbackChange,
}) => {
  
  const handleThumbUpClick = () => {
    onFeedbackChange(2);
  };

  const handleThumbDownClick = () => {
    onFeedbackChange(3);
  };

  return (
    <Accordion
      style={{
        backgroundColor: '#43688B',
        color: 'white',
        marginBottom: '10px',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Heading>{title}</Heading>
      </AccordionSummary>
      <AccordionDetails style={{ marginRight: '10px' }}>
        <Typography>{content}</Typography>
      </AccordionDetails>
      <ThumbContainer>
        <ThumbUp
          style={{ 
            cursor: 'pointer', 
            marginLeft: '8px', 
            color: feedback === 2 ? '#4FD144' : '#FFFFFF' // Verde se positivo
          }}
          onClick={handleThumbUpClick}
        />
        <ThumbDown
          style={{ 
            cursor: 'pointer', 
            marginLeft: '8px', 
            color: feedback === 3 ? '#FF3F3F' : '#FFFFFF' // Vermelho se negativo
          }}
          onClick={handleThumbDownClick}
        />
      </ThumbContainer>
    </Accordion>
  );
};

export default CardAccordion;