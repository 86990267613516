import { Request } from '~/services';
import { alert, REACT_APP_API_INTEGRATION_ALM_GEN } from '~/utils';
import { getTenantIdCookie } from '~/utils/tenants';

export const getProjects = async (): Promise<any> => {
    try {
      const tenantId = getTenantIdCookie();
      const response = await Request(REACT_APP_API_INTEGRATION_ALM_GEN!).get(`/projects/${tenantId}`);
      return response.data; 
    } catch ( error: any ) {
      if (error.response?.status === 503){
        alert({  
          message: error.response.data.error,
          type: 'error', 
          position: 'top-center'
        });
      }else{
        alert({
          message: error.message,
          type: 'error', 
          position: 'top-center'
        });
      }
      return false;
    }
  };
